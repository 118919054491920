import { Link, useNavigate } from "react-router-dom";

const HelpPage = () => {
  return (
    <div className='panel-without-sidebar'>
      <div id='help-wrapper'>
        <div>We will add some content here soon.</div>
        <div><p>But while you wait, <Link to='/contact'>contact  us</Link> for any queries or a quote</p></div>
      </div>
    </div>

  );
};

export default HelpPage;
