const AboutPage = () => {
  return (
    <div className='panel-without-sidebar'>
      <div id="about-wrapper">
        <p>We move everything! Comyn Vervoer.</p>
        <p></p>
        <p>Contact us for a quote or more information.</p>
      </div>
    </div>
  );
};

export default AboutPage;
