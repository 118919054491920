import { Navbar, NavbarBrand } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import { isMobileOnly, isDesktop } from 'react-device-detect';
import logo from '../assets/images/CVLogoWeb.png'

const CVIcon = () => {
  return (
    <svg
      id='comyn-vervoer-icon-svg'
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      aria-labelledby="title"
      color='rgb(26, 33, 238)'
      strokeWidth={1}
    >
      <title id="title">Comyn Vervoer Icon</title>
      <polyline points="17.000 11.000 12.518 11.000 9.255 34.893 32.6 34.894 35.425 11.000 22.000 11.000" />
      <line x1="2.22" y1="18.741" x2="18.849" y2="18.663" />
      <line x1="4.565" y1="24.593" x2="8.687" y2="24.515" />
      <line x1="6.271" y1="29.196" x2="16.788" y2="29.119" />
      <path d="M 32.83 34.874 L 45.191 34.979 C 45.876 34.144 46.748 22.818 33.71 24.336" transform="matrix(0.99999, -0.004363, 0.004363, 0.99999, -0.128718, 0.171207)" />
    </svg>
  )
}

const NavbarBrandText = () => {
  if (isDesktop)
    return (<span id='brand-text'>Comyn Vervoer</span>)
  return (<></>)
}

const TopNavigation = () => {
  //const navigate = useNavigate();
  return (
    <Navbar id='top-navigation'>
      <div className="nav-wrapper">
        <NavbarBrand href='/'>
          {/* <CVIcon width='75px' height='75px' />
          <NavbarBrandText /> */}
          <img id='logo-image' src={logo} alt='Comyn Vervoer Logo' />
        </NavbarBrand>
        <div className="nav-section nav-menu">
          <ul className={isMobileOnly ? 'mobile' : ''}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
          </ul>
        </div>
        <div className="nav-section nav-authstatus">
        </div>
      </div>
    </Navbar>
  )
}

export default TopNavigation;
