import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'
import image1 from '../assets/images/gallery/CV_001.jpg'
import thumbnail1 from '../assets/images/gallery/CV_T_001.jpg'
import image2 from '../assets/images/gallery/CV_002.jpg'
import thumbnail2 from '../assets/images/gallery/CV_T_002.jpg'
import image3 from '../assets/images/gallery/CV_003.jpg'
import thumbnail3 from '../assets/images/gallery/CV_T_003.jpg'
import image4 from '../assets/images/gallery/CV_004.jpg'
import thumbnail4 from '../assets/images/gallery/CV_T_004.jpg'
import image5 from '../assets/images/gallery/CV_005.jpg'
import thumbnail5 from '../assets/images/gallery/CV_T_005.jpg'
import image6 from '../assets/images/gallery/CV_006.jpg'
import thumbnail6 from '../assets/images/gallery/CV_T_006.jpg'
import image7 from '../assets/images/gallery/CV_007.jpg'
import thumbnail7 from '../assets/images/gallery/CV_T_007.jpg'
import image8 from '../assets/images/gallery/CV_008.jpg'
import thumbnail8 from '../assets/images/gallery/CV_T_008.jpg'

const GalleryPage = () => {
  const smallItemStyles = {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '100%',
    maxHeight: '100%',
    margin: '3px',
  }

  return (
    <div className='panel-without-sidebar'>
      <div id='gallery-wrapper'>
        <Gallery id="simple-gallery">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '240px 240px 240px 240px',
              gridTemplateRows: '180px 180px',
              gridGap: 12,
            }}
          >
            <Item
              original={image1}
              thumbnail={thumbnail1}
              width="1280"
              height="960"
              title="Comyn vervoer image 1"
              id="so-first"
            >
              {({ ref, open }) => (
                <img
                  style={{ cursor: 'pointer' }}
                  src={thumbnail1}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 1"
                />
              )}
            </Item>
            <Item
              original={image2}
              thumbnail={thumbnail2}
              width="1280"
              height="960"
              title="Comyn vervoer image 2"
              id="so-two"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src={thumbnail2}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 2"
                />
              )}
            </Item>
            <Item
              original={image3}
              thumbnail={thumbnail3}
              width="1280"
              height="960"
              title="Comyn vervoer image 3"
              id="so-three"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src={thumbnail3}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 3"
                />
              )}
            </Item>
            <Item
              original={image4}
              thumbnail={thumbnail4}
              width="640"
              height="480"
              title="Comyn vervoer image 4"
              id="so-three"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src={thumbnail4}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 4"
                />
              )}
            </Item>
            <Item
              original={image5}
              thumbnail={thumbnail5}
              width="1280"
              height="960"
              title="Comyn vervoer image 5"
              id="so-three"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src={thumbnail5}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 5"
                />
              )}
            </Item>
            <Item
              original={image6}
              thumbnail={thumbnail6}
              width="640"
              height="480"
              title="Comyn vervoer image 6"
              id="so-three"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src={thumbnail6}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 6"
                />
              )}
            </Item>
            <Item
              original={image7}
              thumbnail={thumbnail7}
              width="640"
              height="480"
              title="Comyn vervoer image 7"
              id="so-three"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src={thumbnail7}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 7"
                />
              )}
            </Item>
            <Item
              original={image8}
              thumbnail={thumbnail8}
              width="640"
              height="480"
              title="Comyn vervoer image 8"
              id="so-three"
            >
              {({ ref, open }) => (
                <img
                  style={smallItemStyles}
                  src={thumbnail8}
                  ref={ref}
                  onClick={open}
                  alt="Comyn vervoer 8"
                />
              )}
            </Item>
          </div>
        </Gallery>
      </div>
    </div>

  );
};

export default GalleryPage;
