import { Link } from 'react-router-dom'
import { Nav, NavItem, Row } from 'reactstrap';

const SiteFooter = () => {
  return (
    <footer id='footer-wrapper'>
      <Row id='footer-content'>
        <div id='footer-toggle'>
          <svg id="footer-toggle-button">
            <polygon points="30,0 60,30 0,30" />
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        <div id='footer-section-1' className='footer-section'></div>
        <div id="footer-support" className='footer-section'>
          <h5>Support</h5>
          <Nav vertical>
            <NavItem>
              <Link to='/help'>Help</Link>
            </NavItem>
          </Nav>
        </div>
        <div id="footer-company" className='footer-section'>
          <h5>Company</h5>
          <Nav vertical>
            <NavItem>
              <Link to='/contact'>Contact us</Link>
            </NavItem>
            <NavItem>
              <Link to='/about'>About us</Link>
            </NavItem>
          </Nav>
        </div>
        <div id="footer-legal" className='footer-section'>
          <h5>Legal</h5>
          <Nav vertical>
            <NavItem>
              <Link to='/terms'>Terms and conditions</Link>
            </NavItem>
          </Nav>
        </div>
        <div className='footer-section'></div>
      </Row>
      <Row id='footer-copyright'>
        <div id='copyright' >
          <p>
            © Copyright 2022 Comyn Vervoer
          </p>
        </div>
      </Row>
    </footer >
  )
}

export default SiteFooter;
