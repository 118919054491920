import { useRef } from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { isMobile } from 'react-device-detect';

import image1 from '../assets/images/Image2_desktop.jpg';
import image2 from '../assets/images/Image2_mobile.jpg';

const LayerTwoBackground = () => {
  if (isMobile)
    return (<img id='parallax-layer-2-mobile-image' src={image2} alt='Truck driving in a valley optimised for mobile devices' />)
  return (<img id='parallax-layer-2-desktop-image' src={image1} alt='Truck driving in a valley optimised for desktop' />)
}

const HomePage = () => {
  const parallax = useRef(null)
  return (
    <div id='home-page'>
      <Parallax id='parallax-container' ref={parallax} pages={4} style={{ top: '0', left: '0' }} >
        <ParallaxLayer
          id='parallax-layer-0'
          className='parallax-layer'
          offset={0}
          speed={0}
          onClick={() => parallax?.current?.scrollTo(1)}
        >
          <p><span className={isMobile ? 'bold-heading mobile' : 'bold-heading'}>Comyn vervoer is your partner in logistics</span></p>
          <p><span className={isMobile ? 'bold-heading mobile' : 'bold-heading'}>Convenient - Reliable - Professional</span></p>
        </ParallaxLayer>

        <ParallaxLayer
          id='parallax-layer-1'
          className='parallax-layer'
          offset={1}
          speed={0}
          factor={1}
          onClick={() => parallax?.current?.scrollTo(2)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            backgroundColor: 'cornflowerblue',
          }}>
          {/* <p>Keep scrolling to see more</p> */}
          <iframe
            width="460"
            height="817"
            src="https://www.youtube.com/embed/qt9S2JKetrE?autoplay=1&amp;loop=1&amp;mute=1&amp;playlist=qt9S2JKetrE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop;"
            allowfullscreen
          >
          </iframe>
        </ParallaxLayer>

        <ParallaxLayer
          id='parallax-layer-2'
          className='parallax-layer'
          offset={2}
          speed={1}
          factor={1}
          onClick={() => parallax?.current?.scrollTo(3)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <LayerTwoBackground />
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          id='parallax-layer-4'
          className='parallax-layer'
          offset={2.3}
          speed={-0.2}
          factor={1}
          onClick={() => parallax?.current?.scrollTo(3)}
          style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', marginTop: 0, marginBottom: 0 }}
        >
          <p><span className='bold-heading'>Let's get you moving!</span></p>
        </ParallaxLayer>

        <ParallaxLayer
          id='parallax-layer-3'
          className='parallax-layer'
          offset={3}
          speed={0}
          onClick={() => parallax?.current?.scrollTo(0)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            backgroundColor: 'greenyellow',
          }}>
          <div id='parallax-layer-3-content'>
            <p>
              <span className={isMobile ? 'bold-heading mobile' : 'bold-heading'}>
                Hover the mouse pointer over the arrow at the bottom of the screen to see more ...
              </span>
            </p>
          </div>
        </ParallaxLayer>

      </Parallax>
    </div >
  )
}

export default HomePage;
